import React from "react";
import { useQuery } from "react-query";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  formatDate_12h,
  overviewListItemStyles,
} from "../../../services/Utils";
import {
  getVehicleServices,
  getServiceContractAuditLogs,
} from "../../../services/vehicle";

const RoutingVehicleService = ({ vehicleData, isLoading }) => {
  const { device: { vehicleKey = null } = {} } = vehicleData || {};

  const { data = [] } = useQuery(
    ["RoutingVehicleService", vehicleKey],
    getVehicleServices,
    {
      enabled: !!vehicleKey,
      refetchOnWindowFocus: false,
    }
  );

  const { data: serviceContractAuditData = [] } = useQuery(
    ["RoutingServiceContract", vehicleKey],
    getServiceContractAuditLogs,
    {
      enabled: !!vehicleKey,
      refetchOnWindowFocus: false,
    }
  );

  const serviceContractAudit = serviceContractAuditData?.data || [];

  const vehicleServices = data?.data;

  return (
    <Box>
      <Grid container sx={{ pt: "30px" }}>
        <Grid item xs={6} sx={{ p: "12px" }}>
          <Card>
            <CardHeader
              title="Service Contract"
              titleTypographyProps={{ variant: "subtitle2" }}
              sx={{
                backgroundColor: (theme) => theme.palette.tertiary,
              }}
            />
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    <List>
                      <ListItem {...overviewListItemStyles}>
                        <ListItemText
                          primary="RS Contract"
                          secondary={
                            vehicleServices?.routingServerContractStatus
                              ? vehicleServices?.routingServerContractStatus
                              : "-"
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem {...overviewListItemStyles}>
                        <ListItemText
                          primary="Telematics Contract"
                          secondary={
                            vehicleServices?.telematicsContractStatus
                              ? vehicleServices?.telematicsContractStatus
                              : "-"
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sx={{ p: "12px" }}>
          <Card>
            <CardHeader
              title="Service Data"
              titleTypographyProps={{ variant: "subtitle2" }}
              sx={{
                backgroundColor: (theme) => theme.palette.tertiary,
              }}
            />
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    <List>
                      <ListItem {...overviewListItemStyles}>
                        <ListItemText
                          primary="Data Status"
                          secondary={
                            vehicleServices?.dataEnabled
                              ? vehicleServices?.dataEnabled
                              : "ENABLED"
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={3}>
                    <List>
                      <ListItem {...overviewListItemStyles}>
                        <ListItemText
                          primary="Data Usage"
                          secondary={
                            vehicleServices?.dataUsage
                              ? `${vehicleServices?.dataUsage}%`
                              : "-"
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {serviceContractAudit?.length > 0 && (
        <div style={{ marginTop: "32px" }}>
          <Typography
            sx={{ flex: "1 1 100%", ml: "12px" }}
            variant="button"
            id="tableTitle"
            component="div"
          >
            Service Contract Audit Logs
          </Typography>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Contract Action</TableCell>
                  <TableCell>RS Contract</TableCell>
                  <TableCell>Telematics Contract</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceContractAudit.map((row) => (
                  <TableRow
                    key={row?.auditId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>{formatDate_12h(row?.createdAt)}</TableCell>
                    <TableCell>{row?.contractAction}</TableCell>
                    <TableCell>{row?.routingServerContractStatus}</TableCell>
                    <TableCell>{row?.telematicsContractStatus}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Box>
  );
};

export default RoutingVehicleService;
